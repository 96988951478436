import React from 'react';

const media = [
  { src: "photos/bev.jpeg", alt: "hanging out", caption: "hanging out" },
  { src: "photos/8vc_fellowship.jpeg", alt: "8VC engineering fellowship", caption: "8VC engineering fellowship" },
  { src: "photos/brother_match.jpeg", alt: "neurosurgeon brother", caption: "neurosurgeon brother" },
  { src: "photos/family.jpeg", alt: "family time", caption: "family time" },
  { src: "photos/RBC.png", alt: "Royal Bank of Canada Award", caption: "Royal Bank of Canada Award" },
  { src: "photos/nyc_def.jpeg", alt: "co-hosted NYC defense event", caption: "co-hosted NYC defense event" },
  { src: "photos/gundo_def.jpeg", alt: "co-hosted LA defense event", caption: "co-hosted LA defense event" },
  { src: "photos/mayor.jpeg", alt: "Jim Watson (Mayor) supporting my NGO", caption: "Jim Watson (Mayor) supporting my NGO" },
  { src: "photos/hsgrad.jpeg", alt: "HS graduation", caption: "HS graduation" },
  { src: "photos/science_fair.jpeg", alt: "14y/o me presenting research", caption: "14y/o me presenting research" },
  { src: "photos/chillin.jpeg", alt: "chillin", caption: "chillin" },
  { src: "photos/china_study.jpeg", alt: "study abroad in China", caption: "study abroad in China" },
  { src: "photos/family_sc.jpeg", alt: "young me", caption: "young me" },
  { src: "photos/mom_hug.jpeg", alt: "mom hug", caption: "Palos Verdes with mom" },
  { src: "photos/pasta.jpeg", alt: "pasta", caption: "handmade pasta" },
  { src: "photos/harmony.jpeg", alt: "Formal", caption: "'formal'" },
  { src: "photos/india_fam.jpeg", alt: "India", caption: "family in India" },
  { src: "photos/UCLA_grad.jpeg", alt: "UCLA", caption: "UCLA graduation" },
  { src: "photos/SF_sky.jpeg", alt: "SF", caption: "SF from above" },
  { src: "photos/diwali.png", alt: "diwali", caption: "diwali 2020" },
  { src: "photos/piano_interstellar.mp4", alt: "interstellar", caption: "interstellar theme", type: "video" },
];

function PhotoCollage() {
  const renderMedia = (item, index) => {
    const isVideo = item.type === "video";
    
    return (
      <figure key={index}>
        {isVideo ? (
          <video 
            controls 
            playsInline 
            muted
            style={{ maxWidth: '100%', height: 'auto' }}
          >
            <source src={item.src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={item.src} alt={item.alt} />
        )}
        <figcaption>{item.caption}</figcaption>
      </figure>
    );
  };

  return (
    <div className="photo-collage">
      {media.map((item, index) => renderMedia(item, index))}
    </div>
  );
}

export default PhotoCollage;